import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { a, useSpring } from "react-spring"
import { useSiteMetadata } from "helpers/hooks"
import { useInView } from "react-intersection-observer"
import { H1, P as Paragraph } from "components/typography"
import {
  BACKGROUND,
  FONT,
  mqHandheld,
  mqTablet,
  mqTabletHandheld,
} from "components/index.css"
import SEO from "../components/seo"
import S from "components/sticker"

const mapUrl = `https://maps.apple.com/?address=Rúa%20de%20Juan%20Flórez,%20129,%202°,%20DCHA,%2015005%20A%20Coruña,`
const gMapUrl = `https://www.google.com/maps/place/R%C3%BAa+Juan+Fl%C3%B3rez,+129,+2%C2%BA,+15005+A+Coru%C3%B1a`

const ContactPage = () => {
  const { email } = useSiteMetadata()
  const [stickerRef, stickerInView] = useInView({
    triggerOnce: true,
    threshold: 1,
  })

  const springProps = useSpring({
    from: { opacity: 0, transform: `translateX(-20%)` },
    to: { opacity: 1, transform: `translateX(0)` },
  })

  const { complianceLogo } = useStaticQuery(graphql`
    query {
      complianceLogo: file(relativePath: { eq: "compliance-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 114) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Contacto" />
      <H1>&iquest;hablamos?</H1>
      <Column>
        <Row style={springProps}>
          <Address target="_blank" rel="nofollow" href={gMapUrl}>
            <P>
              Calle Juan Fl&oacute;rez <br />
              129 2&ordm; Dcha. 15005,
              <br />A Coru&ntilde;a.
            </P>
          </Address>
          <ContactLinks>
            <A href={`mailto:${email}`}>{`${email}`}</A>
            <A href="tel:881246145">881 246 145</A>
            <A href="tel:611668865">611 668 865</A>
          </ContactLinks>
          <StickerWrapper ref={stickerRef}>
            <Sticker side="right" inView={stickerInView}>
              <P>&amp;</P>
              <P>estamos</P>
              <P>a tu lado</P>
              <P>cuando nos</P>
              <P>necesites</P>
            </Sticker>
          </StickerWrapper>
        </Row>
        <Row style={springProps}>
          <SocialLinks>
            <A
              href="https://www.linkedin.com/company/keep-comp/"
              rel="nofollow"
              target="_blank"
            >
              linkedin
            </A>{" "}
            /<br />
            <A
              href="https://www.instagram.com/keepcomp_oficial/"
              rel="nofollow"
              target="_blank"
            >
              instagram
            </A>{" "}
            /{" "}
            <A
              href="https://www.facebook.com/Keepcomp/"
              rel="nofollow"
              target="_blank"
            >
              facebook
            </A>
          </SocialLinks>
          <ImageWrapper>
            <Image fluid={complianceLogo.childImageSharp.fluid} />
          </ImageWrapper>
        </Row>
      </Column>
    </>
  )
}

export default ContactPage

const Sticker = styled(S)(({ side }) => ({
  background: `rgba(0, 232, 0, 1)`,
  marginRight: 40,
  alignSelf: `flex-end`,
  "> div": {
    paddingBottom: 10,
  },
}))

const StickerWrapper = styled.div({
  position: `relative`,
  display: `flex`,
  justifyContent: `flex-end`,
  alignItems: `flex-end`,

  [mqTablet]: {
    flex: 0,
  },
  [mqHandheld]: {
    flex: 1,
  },
})

const Column = styled.div({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-evenly`,
  flex: 1,
  margin: `15px 0`,

  [mqTablet]: {
    justifyContent: `space-around`,
  },
})

const Row = styled(a.div)({
  display: `flex`,
  flexWrap: `wrap`,
  fontFamily: `RecoletaLight`,
  fontSize: 36,
  lineHeight: `44px`,
})

const Address = styled.a({
  marginBottom: 55,
  marginRight: 10,
  flex: `0 1 40%`,
  "> p": {
    boxShadow: `inset 0 -0.25em ${BACKGROUND}`,
    display: `inline`,
    textShadow: `
      2px 2px rgb(${BACKGROUND}),
      2px -2px rgb(${BACKGROUND}),
      -2px 2px rgb(${BACKGROUND}),
      -2px -2px rgb(${BACKGROUND})
    `,
  },
  "&:hover": {
    "> p": {
      boxShadow: `
        inset 0 -0.21em rgb(${BACKGROUND}),
        inset 0 -0.25em ${FONT}
      `,
      display: `inline`,
      textShadow: `
        2px 2px rgb(${BACKGROUND}),
        2px -2px rgb(${BACKGROUND}),
        -2px 2px rgb(${BACKGROUND}),
        -2px -2px rgb(${BACKGROUND})
      `,
    },
  },

  [mqTabletHandheld]: {
    "> p": {
      display: `block`,
    },
  },
  [mqTablet]: {
    flex: `1 1 50%`,
  },
  [mqHandheld]: {
    flex: `1 1 100%`,
    marginBottom: 25,
  },
})

const P = styled(Paragraph)({
  fontFamily: `RecoletaLight`,
  fontSize: 42,
  lineHeight: `46px`,
  margin: 0,

  [mqTabletHandheld]: {
    margin: 0,
  },
})

const A = P.withComponent("a")

const ContactLinks = styled(Address)({
  flex: `0 1 40%`,
  display: `flex`,
  flexDirection: `column`,
  "> a": {
    marginRight: `auto`,
  },
  "&:hover": {
    textDecoration: `none`,
  },
  [mqHandheld]: {
    marginBottom: 0,
  },
}).withComponent("div")

const SocialLinks = styled.span({
  marginTop: `auto`,
  flex: `0 1 40%`,

  [mqTabletHandheld]: {
    flex: 2,
  },
})

const ImageWrapper = styled.div({
  flex: `0 1 40%`,
  [mqTablet]: {
    flex: 1,
    margin: `auto`,
  },
  [mqHandheld]: {
    flex: 0,
  },
})

const Image = styled(Img)({
  width: 113,
  [mqTablet]: {
    margin: `auto`,
  },
  [mqHandheld]: {
    width: 80,
  },
})
