import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { mqHandheld, mqTabletHandheld } from "components/index.css"
import { a } from "react-spring"

export const H1 = styled.h1({
  fontFamily: `Recoleta`,
  fontWeight: 100,
  fontSize: 65,
  lineHeight: `90px`,
  margin: 0,

  [mqHandheld]: {
    fontSize: 50,
    lineHeight: `60px`,
  },
})

export const H2 = styled.h2({
  fontFamily: `RecoletaLight`,
  fontSize: 42,
  margin: 0,
  marginBottom: 44,
  fontWeight: `bolder`,
  lineHeight: `46px`,

  [mqHandheld]: {
    fontSize: 41,
    lineHeight: `45px`,
    marginBottom: 25,

    letterSpacing: `-2px`,
  },
})

export const H3 = styled.h3({
  fontFamily: `RecoletaLight`,
  fontSize: 31,
  lineHeight: `37px`,
  fontWeight: 100,
  margin: 0,
})

export const P = styled.p({
  fontFamily: `RecoletaLight`,
  marginBottom: 30,
  fontSize: 16,
  lineHeight: `20px`,
  "> span": {
    fontWeight: `bolder`,
  },

  [mqHandheld]: {
    fontSize: 24,
    lineHeight: `29px`,
    marginBottom: 30,
  },
})

export const A = styled(P)({
  marginBottom: 0,

  [mqTabletHandheld]: {
    marginBottom: 0,
  },
}).withComponent(`a`)

export const Section = styled(a.section, { shouldForwardProp: isPropValid })(
  ({ marginTop = 60 }) => ({
    marginTop: 60,
    display: `flex`,
    justifyContent: `space-between`,
    flexWrap: `wrap`,
    "> h2": {
      maxWidth: 250,
    },
    "&:last-of-type": {
      marginBottom: 60,
    },
    [mqTabletHandheld]: {
      marginTop,
    },
  })
)
