import React, { useRef, useState } from "react"
import { bool, oneOf, node } from "prop-types"
import { a, config, useChain, useSpring } from "react-spring"
import styled from "@emotion/styled"
import { useWindowSize } from "helpers/hooks"
import { isMobileSafari } from "helpers/browser-detect"
import {
  mqHandheld,
  mqTablet,
  handheldBP,
  tabletMaxBP,
} from "components/index.css"

function getConfig(width) {
  switch (true) {
    case width <= tabletMaxBP:
      return { sizeS: 52, sizeL: 102 }
    default:
      return { sizeS: 47, sizeL: 106 }
  }
}

const Sticker = ({ children, inView, side, ...rest }) => {
  const { width } = useWindowSize()
  const { sizeS, sizeL } = getConfig(width)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const circleSpringRef = useRef()
  const textSpringRef = useRef()

  const circleProps = useSpring({
    config: isFirstLoad ? config.default : config.wobbly,
    ref: circleSpringRef,
    onRest: () => setIsFirstLoad(false),
    from: {
      transform: `translateX(${side === "left" ? "-200%" : "200%"})`,
      height: sizeS,
      width: sizeS,
      opacity: 0,
    },
    to: {
      opacity: inView || isExpanded ? 1 : 0,
      width: isExpanded ? sizeL : sizeS,
      height: isExpanded ? sizeL : sizeS,
      transform: inView
        ? `translateX(0)`
        : `translateX(${side === "left" ? "-200%" : "200%"})`,
    },
  })

  const textProps = useSpring({
    config: isExpanded
      ? config.stiff
      : { mass: 1, tension: 500, friction: 0, clamp: true },
    ref: textSpringRef,
    from: { opacity: 0, transform: `rotate(0deg)` },
    to: {
      transform: isExpanded
        ? `rotate(${side === "left" ? "-" : ""}15deg)`
        : `rotate(0deg)`,
      opacity: isExpanded ? 1 : 0,
    },
  })

  useChain(
    isExpanded
      ? [circleSpringRef, textSpringRef]
      : [textSpringRef, circleSpringRef],
    [0, 0.3]
  )

  function toggle(e) {
    setIsExpanded(!isExpanded)
  }

  function handleHover(e) {
    if (isMobileSafari()) return // hack: this would mean double click in iOS
    setIsExpanded(true)
  }

  return (
    <Wrapper height={sizeL}>
      <Circle
        style={circleProps}
        onMouseEnter={handleHover}
        onMouseDown={toggle}
        {...rest}
      >
        <Text style={textProps}>{children}</Text>
      </Circle>
    </Wrapper>
  )
}

Sticker.propTypes = {
  children: node.isRequired,
  inView: bool.isRequired,
  side: oneOf([`left`, `right`]).isRequired,
}

export default Sticker

const Wrapper = styled.div(({ height }) => ({
  display: `flex`,
  height,
  minWidth: 106,
}))

const Circle = styled(a.div)({
  borderRadius: `50%`,
  display: `inline-flex`,
  justifyContent: `center`,
  alignItems: `center`,
  textAlign: `center`,
  overflow: `hidden`,
  cursor: `pointer`,
  margin: `auto 0`,
})

const Text = styled(a.div)({
  opacity: 0,
  "> p": {
    margin: 0,
    fontFamily: "Recoleta",
    fontSize: 15,
    lineHeight: `16px`,
  },
})
